import { useCallback } from "react";

import useAPI from "./useAPI";
import { CodeLanguages } from "../types";
import useStore from "../utils/store";

type useCodeProps = {
  generateCode: (
    description: string,
    codeLanguage: CodeLanguages,
    code?: string,
    codeGuidelines?: string
  ) => Promise<any>;
  publishCode: (
    codeSessionId: string,
    codeLanguage: CodeLanguages,
    code: string
  ) => Promise<any>;
  renderCode: (codeSessionId: string) => Promise<any>;
  listCodes: () => Promise<any>;
  createCode: (
    codeLanguage: CodeLanguages,
    codeName: string,
    codeGuidelines: string
  ) => Promise<any>;
};

const useCode = (): useCodeProps => {
  const { request, fetchRequest } = useAPI();
  const { authToken }: any = useStore();

  const generateCode = useCallback(
    async (
      description: string,
      codeLanguage: CodeLanguages,
      code?: string,
      codeGuidelines?: string
    ) => {
      const path = "/code/generate";
      const config = {
        token: authToken,
        data: {
          description,
          codeLanguage,
          ...(code && { code }),
          ...(codeGuidelines && { codeGuidelines })
        }
      };
      const operation = await fetchRequest(path, config);

      if (!operation) return;

      return operation;
    },
    [authToken]
  );

  const publishCode = useCallback(
    async (
      codeSessionId: string,
      codeLanguage: CodeLanguages,
      code: string
    ) => {
      const path = "/code/publish";
      const config = {
        token: authToken,
        data: {
          codeSessionId,
          codeLanguage,
          code
        }
      };
      const operation = await request(path, config);

      if (!operation) return;

      return operation;
    },
    [authToken]
  );

  const renderCode = useCallback(async (codeSessionId: string) => {
    const path = "/code/render";
    const config = {
      data: {
        codeSessionId
      }
    };
    const operation = await request(path, config);

    if (!operation) return;

    return operation;
  }, []);

  const listCodes = useCallback(async () => {
    const path = "/code/list";
    const config = {
      token: authToken
    };
    const operation = await request(path, config);

    if (!operation) return;

    return operation;
  }, [authToken]);

  const createCode = useCallback(
    async (
      codeLanguage: CodeLanguages,
      codeName: string,
      codeGuidelines: string
    ) => {
      const path = "/code/create";
      const config = {
        token: authToken,
        data: {
          codeName,
          codeLanguage,
          codeGuidelines
        }
      };
      const operation = await request(path, config);

      if (!operation) return;

      return operation;
    },
    [authToken]
  );

  return { generateCode, publishCode, renderCode, listCodes, createCode };
};

export default useCode;
