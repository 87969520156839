import { useCallback } from "react";

import useAPI from "./useAPI";

type useAuthProps = {
  signUp: (name: string, email: string, password: string) => Promise<any>;
  verifyEmail: (token: string) => Promise<any>;
  signIn: (email: string, password: string) => Promise<any>;
  forgotPassword: (email: string) => Promise<any>;
  resetPassword: (token: string, password: string) => Promise<any>;
  resendVerifyEmail: (email: string) => Promise<any>;
};

const useAuth = (): useAuthProps => {
  const { request } = useAPI();

  const signUp = useCallback(
    async (name: string, email: string, password: string) => {
      const path = "/auth/signup";
      const config = {
        data: {
          name,
          email,
          password
        }
      };
      const operation = await request(path, config);

      if (!operation) return;

      return operation;
    },
    []
  );

  const verifyEmail = useCallback(async (token: string) => {
    const path = "/auth/verify-email";
    const config = {
      data: {
        token
      }
    };
    const operation = await request(path, config);

    if (!operation) return;

    return operation;
  }, []);

  const signIn = useCallback(async (email: string, password: string) => {
    const path = "/auth/signin";
    const config = {
      data: {
        email,
        password
      }
    };
    const operation = await request(path, config);

    if (!operation) return;

    return operation;
  }, []);

  const forgotPassword = useCallback(async (email: string) => {
    const path = "/auth/password/forget";
    const config = {
      data: {
        email
      }
    };

    const operation = await request(path, config);

    if (!operation) return;

    return operation;
  }, []);

  const resetPassword = useCallback(async (token: string, password: string) => {
    const path = "/auth/password/reset";
    const config = {
      data: {
        password,
        token
      }
    };

    const operation = await request(path, config);

    if (!operation) return;

    return operation;
  }, []);

  const resendVerifyEmail = useCallback(async (email: string) => {
    const path = "/auth/verify-email/resend";
    const config = {
      data: {
        email
      }
    };

    const operation = await request(path, config);

    if (!operation) return;

    return operation;
  }, []);

  return {
    signUp,
    verifyEmail,
    signIn,
    forgotPassword,
    resetPassword,
    resendVerifyEmail
  };
};

export default useAuth;
