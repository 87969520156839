import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { toastSuccess } from "../../utils/toast";
import { Button } from "../Button";

const VerifyEmail = (props: any) => {
  const { token, email } = props;
  const { verifyEmail, resendVerifyEmail } = useAuth();
  const [verified, setVerified] = useState<null | boolean>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (token) {
      verifyUser();
    }
  }, [token]);

  const verifyUser = async () => {
    const operation = await verifyEmail(token);
    const { message } = operation;
    switch (message) {
      case "Email verified successfully.":
        setVerified(true);
        break;
      default:
        setVerified(false);
        break;
    }
  };

  const resendVerificationEmail = async () => {
    setIsLoading(true);
    const operation = await resendVerifyEmail(email);
    const { message } = operation;
    switch (message) {
      case "User verification email sent successfully":
        toastSuccess(message);
        break;
      default:
        break;
    }
    setIsLoading(false);
  };

  return (
    <section className="h-screen flex items-center justify-center bg-no-repeat inset-0 bg-cover">
      <div className="container 2xl:px-80 xl:px-52">
        <div
          className="bg-white rounded-lg p-5"
          style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
        >
          <div className="grid xl:grid-cols-5 lg:grid-cols-3 gap-6">
            <div className="xl:col-span-2 lg:col-span-1 hidden lg:block">
              <div className="bg-[#011d29] text-white rounded-lg flex flex-col justify-between gap-10 h-full w-full p-7">
                <a
                  className="flex title-font font-medium text-white mb-4 md:mb-0 pr-4"
                  href="/"
                >
                  <img width="200" src="/codeXpert-white.png" alt="logo" />
                </a>
                <div>
                  <h1 className="text-2xl/tight mb-4">Ready to level up?</h1>
                  <p className="text-gray-200 font-normal leading-relaxed">
                    We are here to help you get better at coding.
                  </p>
                </div>
                <div>
                  <div className="bg-sky-700/50 rounded-lg p-5">
                    <p className="text-gray-200 text-sm font-normal leading mb-4">
                      Without a doubt, CodeXpert has been a game changer for me,
                      significantly boosting my productivity.
                    </p>
                    <div className="flex items-center gap-4">
                      <img
                        src="/images/avatar/3.png"
                        alt=""
                        className="h-12 rounded-lg"
                      />
                      <div>
                        <p className="font-normal">Chris K.</p>
                        <span className="text-xs font-normal">
                          Junior Developer
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="xl:col-span-3 lg:col-span-2 lg:mx-10 my-auto">
              <div>
                <h1 className="text-2xl/tight mb-3 text-[#011d29]">
                  Email Verification
                </h1>
                {!token && (
                  <>
                    <p className="text-sm font-medium leading-relaxed text-[#011d29]">
                      Please check your email to complete your account
                      verification.
                    </p>
                    <br />
                    <p className="text-sm text-gray-500">
                      Didn't receive an email yet?
                      <Button
                        label="Send again"
                        onClick={resendVerificationEmail}
                        className="text-[black] hover:text-[#011d29]"
                        isLoading={isLoading}
                      />
                    </p>
                  </>
                )}
              </div>

              {token &&
                (!verified ? (
                  <div className="mt-10">
                    <p className="text-sm font-medium leading-relaxed text-[#011d29]">
                      We were not able to verify your email!
                    </p>
                    <div className="flex flex-wrap items-center justify-between gap-6 mt-8">
                      <Link
                        to="/sign-in"
                        className="bg-[#011d29] text-white text-sm rounded-lg px-6 py-2.5"
                      >
                        Sign In
                      </Link>
                    </div>
                  </div>
                ) : (
                  <div className="mt-10">
                    <p className="text-sm font-medium leading-relaxed text-[#011d29]">
                      Your email is verified successfully. Please Sign in!
                    </p>
                    <div className="flex flex-wrap items-center justify-between gap-6 mt-8">
                      <Link
                        to="/sign-in"
                        className="bg-[#011d29] text-white text-sm rounded-lg px-6 py-2.5"
                      >
                        Sign In
                      </Link>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VerifyEmail;
