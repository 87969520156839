import { ButtonHTMLAttributes, DetailedHTMLProps } from "react";
import { FaSpinner } from "react-icons/fa";

interface ButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  label: string | JSX.Element;
  isLoading?: boolean;
  isActive?: boolean;
}

export const Button = ({
  label,
  isLoading,
  isActive,
  className = "",
  onClick,
  ...props
}: ButtonProps) => (
  <button
    className={`btn px-4 cursor-pointer hover:text-white disabled:text-gray-400 disabled:opacity-50 disabled:cursor-not-allowed ${className} ${
      isActive ? "text-white" : "text-gray-400"
    }`}
    onClick={!isLoading ? onClick : undefined}
    {...props}
  >
    {isLoading ? <FaSpinner className="animate-spin" /> : label}
  </button>
);
