export const examples = [
  {
    exampleName: "Tweet UI",
    examplePrompt:
      "a tweet UI, in the middle of a page. Make sure to show multiple ones as a feed.",
    examplePreview: "images/tweet.png"
  },
  {
    exampleName: "Cookies Banner",
    examplePrompt:
      "a cookie consent banner that shows as a modal popup, showing toggles about analytics / marketing / essential and a save preference option.",
    examplePreview: "images/cookies_banner.png"
  },
  {
    exampleName: "Contact Form",
    examplePrompt:
      "a contact form, to collect first/last name email pronoun message with a header and sub header shown in the middle of a black background page.",
    examplePreview: "images/contact_form.png"
  },
  {
    exampleName: "Testimonial Card",
    examplePrompt:
      "a testimonial card with shadow, in the middle of a white background page",
    examplePreview: "images/testimonial_card.png"
  },
  {
    exampleName: "FAQ Section",
    examplePrompt:
      "an FAQ section with some dummy contact, using collapsable cards on each FAQ question",
    examplePreview: "images/faq_section.png"
  },
  {
    exampleName: "Newsletter Section",
    examplePrompt: "a newsletter section with an email input",
    examplePreview: "images/newsletter_section.png"
  }
];

export const techStacks = [
  "images/reactjs.png",
  "images/html.png",
  "images/bootstrap.png",
  "images/fa.png",
  "images/js.png"
];

export const features = [
  {
    featureName: "Generate Code",
    featureDescription:
      "codeXpert can generate code using voice & text with any language you prefer.",
    featureIcon: "images/generate.png"
  },
  {
    featureName: "Publish Code",
    featureDescription:
      "With one click, you can publish the generated code and share it online.",
    featureIcon: "images/share.png"
  },
  {
    featureName: "Iterate Code",
    featureDescription:
      "codeXpert can iterate on the generated code until you get the desired results.",
    featureIcon: "images/edit.png"
  }
];
