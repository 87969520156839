import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import HomePage from "./pages/HomePage";
import SignInPage from "./pages/SignInPage";
import SignUpPage from "./pages/SignUpPage";
import ForgetPasswordPage from "./pages/ForgetPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import Toast from "./components/Toast";
import VerifyEmailPage from "./pages/VerifyEmailPage";
import useStore from "./utils/store";
import { Analytics } from "@vercel/analytics/react";
import SessionCodePreviewPage from "./pages/SessionCodePreviewPage";
import CodesPage from "./pages/CodesPage";
import CodePage from "./pages/CodePage";

const App = () => {
  const { user }: any = useStore();

  return (
    <div className="flex flex-col flex-1 h-screen font-poppins">
      <Analytics />
      <Toast />
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<HomePage />} />
          <Route path="/sign-in" element={<SignInPage />} />
          <Route path="/sign-up" element={<SignUpPage />} />
          <Route path="/forget-password" element={<ForgetPasswordPage />} />
          <Route
            path="/reset-password/:token"
            element={<ResetPasswordPage />}
          />
          <Route path="/verify-email/:token" element={<VerifyEmailPage />} />
          <Route path="/verify-email" element={<VerifyEmailPage />} />
          <Route
            path="/preview/:sessionId"
            element={<SessionCodePreviewPage />}
          />
          <Route
            path="/code/:codeSessionId"
            element={
              !user ? (
                <Navigate to="/" replace />
              ) : !user?.userEmailVerified ? (
                <Navigate to="/verify-email" replace />
              ) : (
                <CodePage />
              )
            }
          />
          <Route
            path="/codes"
            element={
              !user ? (
                <Navigate to="/" replace />
              ) : !user?.userEmailVerified ? (
                <Navigate to="/verify-email" replace />
              ) : (
                <CodesPage />
              )
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
