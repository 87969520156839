import React from "react";

const Footer = () => {
  return (
    <footer className="bg-black text-white body-font">
      <div className="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
        <a
          className="flex title-font font-medium text-white mb-4 md:mb-0"
          href="/"
        >
          <img
            className="m-auto"
            width="200"
            src="/codeXpert-white.png"
            alt="logo"
          />
        </a>
        <div className="sm:ml-auto sm:mt-0 mt-4">
          <span className="text-gray-600">Contact us at </span>{" "}
          <a href="mailto:info@codexpert.ai"> info@codexpert.ai</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
