import { FaSpinner } from "react-icons/fa";
import React from "react";

const Loader: React.FC<any> = (props) => {
  const { isLoading, loadingMessage } = props;
  return (
    <section className="h-screen flex items-center justify-center bg-no-repeat inset-0">
      <div className="container 2xl:px-80 xl:px-52">
        <div
          className="bg-gray-400 rounded-lg p-5"
          style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
        >
          <div className="grid xl:grid-cols-5 lg:grid-cols-3 gap-6">
            <div className="xl:col-span-2 lg:col-span-1 hidden lg:block">
              <div className="bg-[#011d29] text-white rounded-lg flex flex-col justify-between gap-10 h-full w-full p-7">
                <a
                  className="flex title-font font-medium text-white mb-4 md:mb-0 pr-4"
                  href="/"
                >
                  <img width="200" src="/codeXpert-white.png" alt="logo" />
                </a>
                <div>
                  <h1 className="text-2xl/tight mb-4">Ready to level up?</h1>
                  <p className="text-gray-200 font-normal leading-relaxed">
                    We are here to help you get better at coding.
                  </p>
                </div>
              </div>
            </div>
            <div className="xl:col-span-3 lg:col-span-2 lg:mx-10 my-auto">
              <div>
                <h1 className="text-2xl/tight mb-3 text-[#011d29]">
                  {isLoading
                    ? loadingMessage
                    : "Something went wrong. Try again later!"}
                </h1>
              </div>
              <div className="space-y-5 mt-10">
                <FaSpinner className="text-white animate-spin" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Loader;
