import { useState, useEffect } from "react";
import ErrorMessage from "./ErrorMessage";

interface HtmlCodeRendererProps {
  code: string;
  codeIndex: number;
}

const HtmlCodeRenderer = ({ code, codeIndex }: HtmlCodeRendererProps) => {
  const [error, setError] = useState<string | null>(null);
  const [iframeContent, setIframeContent] = useState<string | null>(null);
  const generateIframeContent = () => {
    return `${code}`;
  };

  const renderComponent = () => {
    try {
      setIframeContent(generateIframeContent());
      setError(null);
    } catch (error) {
      console.error(error);
      setIframeContent(null);
      setError("Failed to render component");
    }
  };

  useEffect(() => {
    renderComponent();
  }, [code]);

  if (error) {
    return (
      <div className="p-2">
        <ErrorMessage error={error} />
      </div>
    );
  }

  return (
    <iframe
      key={`html-${codeIndex}`}
      title="Html Component Render"
      sandbox="allow-same-origin allow-scripts allow-popups allow-modals allow-forms"
      className="w-full h-full"
      srcDoc={(iframeContent as string) || ""}
    />
  );
};

export default HtmlCodeRenderer;
