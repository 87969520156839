import React, { useState } from "react";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { Button } from "../Button";

const ForgetPassword = () => {
  const { forgotPassword } = useAuth();
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const forgetPassword = async () => {
    setIsLoading(true);
    const operation = await forgotPassword(email);
    const { message } = operation;
    switch (message) {
      case "Forget password submitted":
        setSubmitted(true);
        break;
      default:
        break;
    }
    setIsLoading(false);
  };

  const onChange = (e: any) => {
    const {
      value,
      dataset: { property }
    } = e.target;
    switch (property) {
      case "email":
        setEmail(value);
        break;
    }
  };

  return (
    <section className="h-screen flex items-center justify-center bg-no-repeat inset-0 bg-cover">
      <div className="container 2xl:px-80 xl:px-52">
        <div
          className="bg-white rounded-lg p-5"
          style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
        >
          <div className="grid xl:grid-cols-5 lg:grid-cols-3 gap-6">
            <div className="xl:col-span-2 lg:col-span-1 hidden lg:block">
              <div className="bg-[#011d29] text-white rounded-lg flex flex-col justify-between gap-10 h-full w-full p-7">
                <a
                  className="flex title-font font-medium text-white mb-4 md:mb-0 pr-4"
                  href="/"
                >
                  <img width="200" src="/codeXpert-white.png" alt="logo" />
                </a>
                <div>
                  <h1 className="text-2xl/tight mb-4">Ready to level up?</h1>
                  <p className="text-gray-200 font-normal leading-relaxed">
                    We are here to help you get better at coding.
                  </p>
                </div>
                <div>
                  <div className="bg-sky-700/50 rounded-lg p-5">
                    <p className="text-gray-200 text-sm font-normal leading mb-4">
                      Without a doubt, CodeXpert has been a game changer for me,
                      significantly boosting my productivity.
                    </p>
                    <div className="flex items-center gap-4">
                      <img
                        src="/images/avatar/3.png"
                        alt=""
                        className="h-12 rounded-lg"
                      />
                      <div>
                        <p className="font-normal">Chris K.</p>
                        <span className="text-xs font-normal">
                          Junior Developer
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="xl:col-span-3 lg:col-span-2 lg:mx-10 my-auto">
              {submitted ? (
                <>
                  <div>
                    <h1 className="text-2xl/tight mb-3 text-[#011d29]">
                      Forgot Password
                    </h1>
                    <p className="text-sm font-medium leading-relaxed text-[#011d29]">
                      We've sent you a link to reset your password.
                      <br /> Please check your email.
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <h1 className="text-2xl/tight mb-3 text-[#011d29]">
                      Forgot Password
                    </h1>
                    <p className="text-sm font-medium leading-relaxed text-[#011d29]">
                      We'll send you a link to reset your password.
                    </p>
                  </div>
                  <div className="mt-10">
                    <label
                      className="font-medium text-sm block mb-2 text-[#011d29]"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <input
                      data-property="email"
                      type="email"
                      value={email}
                      className="text-[#011d29] border-[#011d29] border-[1px]  focus:ring-0 focus:border-gray-400 text-sm rounded-lg py-2.5 px-4 w-full"
                      onChange={onChange}
                    />
                  </div>
                  <div className="flex flex-wrap items-center justify-between gap-6 mt-8">
                    <Button
                      label="Send Email"
                      className="bg-[#011d29] text-white text-sm rounded-lg px-6 py-2.5"
                      onClick={forgetPassword}
                      isLoading={isLoading}
                    />
                    <p className="text-sm text-gray-500">
                      Don't have an account?{" "}
                      <Link
                        to="/sign-up"
                        className="ms-2 underline text-[#011d29]"
                      >
                        Sign Up
                      </Link>
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgetPassword;
