import { CodeLanguages } from "../types";

const templateContent = {
  title: "How codeXpert Works",
  descriptions: [
    "codeXpert is a powerful tool that helps you generate code automatically, making the development process faster and more efficient.",
    "Here's how codeXpert works:"
  ],
  bullets: [
    "<strong>Intelligent Code Generation:</strong> codeXpert generates code based on the description provided by voice note or input prompt",
    "<strong>Text To Code:</strong> codeXpert generates and renders code using text, you can prompt your idea and it will try to build it.",
    "<strong>Voice To Code:</strong> codeXpert generates and renders code using voice, you can simply click and hold on the mic icon, describe your idea and it will try to build it.",
    "<strong>Code Customization:</strong> codeXpert recognizes the already generated code and allow you to directly update the content of it to fulfill your requirements.",
    "<strong>Code Editing:</strong> codeXpert editor allows you to manually adjust the generated code at any point of time.",
    "<strong>Revisions:</strong> Each session historical iterations will be preserved, so that you can at any time switch between different revisions to revert or adjust from a starting point",
    "<strong>Supported Frameworks:</strong> codeXpert Supports HTML/React code generation, you can export the generated code by simply clicking on the copy/download icon on the topbar",
    "<strong>Continuous Learning:</strong> codeXpert learns from the modifications made by developers and improves its code generation capabilities over time, becoming smarter and more accurate."
  ],
  closing:
    "With codeXpert, developers can learn/focus more on the higher-level aspects of the project and spend less time on repetitive and boilerplate code. It enhances productivity and enables faster development cycles."
};

export const defaultHtmlTemplate = `<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>codeXpert</title>
    <style>
      body {
        font-family: Poppins, sans-serif;
        margin: 0;
        padding: 20px;
      }
      h1 {
        text-align: center;
      }
      p {
        margin-bottom: 10px;
      }
      .container {
        padding: 20px;
        margin: 20px;
        background-color: #f5f5f5;
        border-radius: 5px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      }
    </style>
  </head>
  
  <body>
    <div class="container">
      <h1>${templateContent.title}</h1>
      <p>
        ${templateContent.descriptions[0]}
      </p>
      <p>
        ${templateContent.descriptions[1]}
      </p>
      <ol>
        <li>
          ${templateContent.bullets[0]}
        </li>
        <li>
          ${templateContent.bullets[1]}
        </li>
        <li>
          ${templateContent.bullets[2]}
        </li>
        <li>
          ${templateContent.bullets[3]}
        </li>
        <li>
          ${templateContent.bullets[4]}
        </li>
        <li>
          ${templateContent.bullets[5]}
        </li>
        <li>
          ${templateContent.bullets[6]}
        </li>
      </ol>
      <p>${templateContent.closing}</p>
    </div>
  </body>
</html>`;

export const defaultReactTemplate = `import React from 'react';

const Homepage = () => {
  return (
    <div
      style={{
        fontFamily: "Poppins, sans-serif",
        margin: 20,
        padding: 20,
        backgroundColor: "#f5f5f5",
        borderRadius: 5,
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)"
      }}
    >
      <h1 style={{ textAlign: "center" }}>${templateContent.title}</h1>
      <p style={{ marginBottom: 10 }}>
        ${templateContent.descriptions[0]}
      </p>
      <p style={{ marginBottom: 10 }}>
        ${templateContent.descriptions[1]}
      </p>
      <ol>
        <li>
          ${templateContent.bullets[0]}
        </li>
        <li>
          ${templateContent.bullets[1]}
        </li>
        <li>
          ${templateContent.bullets[2]}
        </li>
        <li>
          ${templateContent.bullets[3]}
        </li>
        <li>
          ${templateContent.bullets[4]}
        </li>
        <li>
          ${templateContent.bullets[5]}
        </li>
        <li>
          ${templateContent.bullets[6]}
        </li>
      </ol>
      <p style={{ marginBottom: 10 }}>
        ${templateContent.closing}
      </p>
    </div>
  );
};

export default Homepage;`;

export const defaultTemplates: Record<CodeLanguages, string> = {
  [CodeLanguages.html]: defaultHtmlTemplate,
  [CodeLanguages.react]: defaultReactTemplate
};
