import React from "react";
import { FaLock } from "react-icons/fa";
import { CodeLanguages } from "../types";
import HtmlCodeRenderer from "./HtmlCodeRenderer";
import ReactCodeRenderer from "./ReactCodeRenderer";

interface CodeRenderProps {
  code: string;
  codeReference: string;
  codeIndex: number;
  codeLanguage: CodeLanguages;
  withWrapper?: boolean;
  isLoading?: boolean;
}

const CodeRender = ({
  codeReference,
  codeLanguage,
  isLoading = false,
  withWrapper = true,
  ...props
}: CodeRenderProps) => {
  const renderCodeComponent = () => {
    switch (codeLanguage) {
      case CodeLanguages.react:
        return <ReactCodeRenderer {...props} />;
      case CodeLanguages.html:
        return <HtmlCodeRenderer {...props} />;
    }
  };

  if (!withWrapper) {
    return <div className="render flex-1">{renderCodeComponent()}</div>;
  }

  return (
    <section className="render-section bg-white h-full w-full overflow-y-hidden flex flex-col">
      <h2 className="p-[10px] shadow-md text-gray-400 leading-[25px] bg-[#011d29]">
        Browser
      </h2>
      <div className="browser border-gray-300 flex-1 flex flex-col">
        <div className="toolbar bg-gray-100 flex items-center p-2">
          <div className="button red bg-red-500 w-4 h-4 rounded-full mr-2" />
          <div className="button yellow bg-yellow-400 w-4 h-4 rounded-full mr-2" />
          <div className="button green bg-green-500 w-4 h-4 rounded-full mr-2" />
          <div className="address-bar bg-white border border-gray-300 rounded-lg flex items-center py-[2px] px-[10px] w-[90%]">
            <FaLock className="text-gray-400 mr-2" />
            <input
              type="text"
              placeholder="Enter URL"
              className="flex-1 outline-none border-none"
              readOnly
              value={`https://${window.location.hostname}/preview/${codeReference}`}
            />
          </div>
        </div>
        {isLoading ? (
          <div className="h-full w-full flex items-center">
            <img
              width="200"
              src="/images/walking_loader.gif"
              alt="loader"
              className="m-auto"
            />
          </div>
        ) : (
          <div className="render flex-1">{renderCodeComponent()}</div>
        )}
      </div>
    </section>
  );
};

export default CodeRender;
