import React, { useState } from "react";

const Header = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  return (
    <header className="text-white body-font">
      <div className="container mx-auto flex flex-wrap p-5 md:flex-row">
        <a
          className="flex title-font font-medium text-white mb-4 md:mb-0 pr-4"
          href="/"
        >
          <img
            className="m-auto"
            width="200"
            src="/codeXpert-white.png"
            alt="logo"
          />
        </a>
        <button
          className="text-white cursor-pointer text-xl leading-none py-1 border border-solid border-transparent rounded bg-transparent block md:hidden outline-none focus:outline-none ml-auto pb-3"
          type="button"
          onClick={() => setNavbarOpen(!navbarOpen)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-menu"
          >
            <line x1="3" y1="12" x2="21" y2="12" />
            <line x1="3" y1="6" x2="21" y2="6" />
            <line x1="3" y1="18" x2="21" y2="18" />
          </svg>
        </button>
        <div
          className={
            "md:flex flex-grow items-center" +
            (navbarOpen ? " flex bg-white w-full" : " hidden")
          }
          id="example-navbar-danger"
        >
          <nav className="md:ml-auto flex flex-wrap items-center text-base justify-center pl-7">
            <a
              className={` ${
                !navbarOpen
                  ? "hover:bg-transparent hover:text-white px-3 py-1 mr-6"
                  : " text-black transition duration-300 hover:text-white"
              } `}
              href="/sign-in"
            >
              Sign In
            </a>
            <a
              className={`px-3 py-1 border-2 border-white rounded-full bg-white text-black ${
                !navbarOpen ? "hover:bg-transparent hover:text-white" : ""
              } transition duration-300`}
              href="/sign-up"
            >
              Sign Up
            </a>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
