import React from "react";
import { Link } from "react-router-dom";

const SplashScreen = () => {
  return (
    <section className="h-screen flex items-center justify-center bg-no-repeat inset-0 bg-cover">
      <div className="container 2xl:px-80 xl:px-52">
        <div
          className="bg-white rounded-lg p-5"
          style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
        >
          <div className="grid xl:grid-cols-5 lg:grid-cols-3 gap-6">
            <div className="xl:col-span-3 lg:col-span-2 lg:mx-10 my-auto">
              <div>
                <h1 className="text-2xl/tight mb-3 text-[#011d29]">
                  Unsupported Device!
                </h1>
                <p className="text-sm font-medium leading-relaxed text-[#011d29]">
                  For the full experience please access codeXpert on a wider
                  screen!
                </p>
                <div className="flex flex-wrap items-center justify-between gap-6 mt-8">
                  <Link
                    to="/"
                    className="bg-[#011d29] text-white text-sm rounded-lg px-6 py-2.5"
                  >
                    Sign Out
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SplashScreen;
