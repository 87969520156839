import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { toastSuccess } from "../../utils/toast";
import { Button } from "../Button";

const SignUp = () => {
  const { signUp } = useAuth();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const signUpUser = async () => {
    setIsLoading(true);
    const operation = await signUp(name, email, password);
    const { message } = operation;
    switch (message) {
      case "User created successfully":
        navigate("/verify-email", { state: { email } });
        toastSuccess(`User created successfully!`);
        break;
      default:
        break;
    }
    setIsLoading(false);
  };

  const onChange = (e: any) => {
    const {
      value,
      dataset: { property }
    } = e.target;
    switch (property) {
      case "name":
        setName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        break;
    }
  };

  return (
    <section className="h-screen flex items-center justify-center bg-no-repeat inset-0 bg-cover">
      <div className="container 2xl:px-80 xl:px-52">
        <div
          className="bg-white rounded-lg p-5"
          style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
        >
          <div className="grid xl:grid-cols-5 lg:grid-cols-3 gap-6">
            <div className="xl:col-span-2 lg:col-span-1 hidden lg:block">
              <div className="bg-[#011d29] text-white rounded-lg flex flex-col justify-between gap-10 h-full w-full p-7">
                <a
                  className="flex title-font font-medium text-white mb-4 md:mb-0 pr-4"
                  href="/"
                >
                  <img width="200" src="/codeXpert-white.png" alt="logo" />
                </a>
                <div>
                  <h1 className="text-2xl/tight mb-4">Ready to level up?</h1>
                  <p className="text-gray-200 font-normal leading-relaxed">
                    We are here to help you get better at coding.
                  </p>
                </div>
                <div>
                  <div className="bg-sky-700/50 rounded-lg p-5">
                    <p className="text-gray-200 text-sm font-normal leading mb-4">
                      Without a doubt, CodeXpert has been a game changer for me,
                      significantly boosting my productivity.
                    </p>
                    <div className="flex items-center gap-4">
                      <img
                        src="/images/avatar/3.png"
                        alt=""
                        className="h-12 rounded-lg"
                      />
                      <div>
                        <p className="font-normal">Chris K.</p>
                        <span className="text-xs font-normal">
                          Junior Developer
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="xl:col-span-3 lg:col-span-2 lg:m-10">
              <div>
                <h1 className="text-2xl/tight text-[#011d29] mb-3">Sign Up</h1>
              </div>
              <div className="space-y-5 mt-10">
                <div>
                  <label
                    className="font-medium text-sm block mb-2 text-[#011d29]"
                    htmlFor="name"
                  >
                    Name
                  </label>
                  <input
                    data-property="name"
                    value={name}
                    className="text-[#011d29] border-[#011d29] border-[1px] focus:ring-0 focus:border-gray-400 text-sm rounded-lg py-2.5 px-4 w-full"
                    onChange={onChange}
                  />
                </div>
                <div>
                  <label
                    className="font-medium text-sm block mb-2 text-[#011d29]"
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <input
                    data-property="email"
                    type="email"
                    value={email}
                    className="text-[#011d29] border-[#011d29] border-[1px] focus:ring-0 focus:border-gray-400 text-sm rounded-lg py-2.5 px-4 w-full"
                    onChange={onChange}
                  />
                </div>
                <div>
                  <label
                    className="font-medium text-sm block mb-2 text-[#011d29]"
                    htmlFor="pwd"
                  >
                    Password
                  </label>
                  <input
                    data-property="password"
                    type="password"
                    value={password}
                    className="text-[#011d29] border-[#011d29] border-[1px] focus:ring-0 focus:border-gray-400 text-sm rounded-lg py-2.5 px-4 w-full"
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="flex flex-wrap items-center justify-between gap-6 mt-8">
                <Button
                  label="Sign Up"
                  className="bg-[#011d29] text-white text-sm rounded-lg px-6 py-2.5"
                  onClick={signUpUser}
                  isLoading={isLoading}
                />
                <p className="text-sm font-medium text-gray-500">
                  Already have an account?{" "}
                  <Link to="/sign-in" className="ms-2 underline text-[#011d29]">
                    Sign In
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignUp;
