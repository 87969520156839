import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

const initialState = {
  user: null,
  codes: null,
  authToken: null
};

const useStore: any = create<any>(
  persist(
    (set: any) => ({
      clearStore: () => set(() => initialState),
      user: null,
      setUser: (user: any) => set({ user }),
      codes: null,
      setCodes: (codes: any) => set({ codes }),
      authToken: null,
      setAuthToken: (authToken: any) => set({ authToken })
    }),
    {
      name: "codexpert-storage",
      storage: createJSONStorage(() => sessionStorage)
    }
  )
);

export default useStore;
