import React from "react";
import ForgetPassword from "../components/webiste/ForgetPassword";
import Footer from "../components/webiste/Footer";
import Header from "../components/webiste/Header";

const ForgetPasswordPage: React.FC = () => {
  return (
    <div className="text-white bg-[transparent]">
      <video
        className="fixed top-0 left-0 w-screen h-screen object-cover z-[-10]"
        controls={false}
        playsInline
        autoPlay
        muted
        loop
      >
        <source src="/home.mp4" type="video/mp4" />
      </video>
      <Header />
      <ForgetPassword />
      <Footer />
    </div>
  );
};

export default ForgetPasswordPage;
