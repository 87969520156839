import React, { useEffect } from "react";

import { FaMicrophone } from "react-icons/fa";
import { useWhisper } from "@chengsokdara/use-whisper";
import { toastError, toastWarning } from "../utils/toast";

const { REACT_APP_OPENAI_KEY: OPENAI_KEY } = process.env;

interface CodeVoiceChatProps {
  submitTranscript: (transcript: string) => void;
  isLoading: boolean;
  setLoading: any;
}

const CodeVoiceChat: React.FC<CodeVoiceChatProps> = ({
  isLoading,
  setLoading,
  submitTranscript
}) => {
  const { recording, transcribing, transcript, startRecording, stopRecording } =
    useWhisper({ apiKey: OPENAI_KEY, removeSilence: true });

  useEffect(() => {
    if (transcript.text && !transcribing) {
      submitTranscript(transcript.text);
    }
  }, [transcript, transcribing]);

  const handleRecordingStart = () => {
    if (isLoading && !recording) {
      toastError("Please wait until generation completes!");
      return null;
    }
    if (!recording) {
      toastWarning("Recording started!");
      startRecording();
      setLoading(true);
      return null;
    }
    stopRecording();
  };

  const handleRecordingStop = () => {
    toastWarning("Recording stopped!");
    stopRecording();
  };

  return (
    <button
      className="btn btn-clear px-4 py-1"
      onMouseDown={handleRecordingStart}
      onMouseUp={handleRecordingStop}
      disabled={isLoading && !recording}
    >
      {recording && (
        <span className="relative flex h-2 w-2 mx-[10px]">
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75" />
          <span className="relative inline-flex rounded-full h-2 w-2 bg-red-600" />
        </span>
      )}
      <FaMicrophone className="text-gray-400 hover:text-white" />
    </button>
  );
};

export default CodeVoiceChat;
