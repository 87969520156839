import React, { useState } from "react";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { Button } from "./Button";
import useCode from "../hooks/useCode";
import { CodeLanguages } from "../types";
import { toastError, toastSuccess } from "../utils/toast";
import useStore from "../utils/store";

type CodeGuidelines = {
  codeGuidelineLogo?: string;
  codeGuidelineFont?: string;
  codeGuidelinePrimaryColor?: string;
  codeGuidelineSecondaryColor?: string;
};

const NewCode: React.FC<any> = () => {
  const [modal, setModal] = useState(false);
  const [codeName, setCodeName] = useState<string | null>(null);
  const [codeLanguage, setCodeLanguage] = useState<CodeLanguages | null>(null);
  const [codeGuidelines, setCodeGuidelines] = useState<CodeGuidelines | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  const toggle = () => setModal(!modal);

  const { createCode } = useCode();
  const { setCodes, codes } = useStore();

  const createCodeAction = async () => {
    setIsLoading(true);
    const operation = await createCode(
      codeLanguage as CodeLanguages,
      codeName as string,
      JSON.stringify(codeGuidelines)
    );
    const { message, code } = operation;
    switch (message) {
      case "Code created successfully":
        setCodes([...codes, code]);
        toastSuccess(`Code created successfully!`);
        break;
      default:
        toastError(`Error creating code!`);
        break;
    }
    toggle();
    setIsLoading(false);
  };

  const onChange = (e: any) => {
    const {
      value,
      dataset: { property }
    } = e.target;
    switch (property) {
      case "codeName":
        setCodeName(value);
        break;
      case "codeLanguage":
        setCodeLanguage(value);
        break;
      // case "codeGuidelineLogo":
      case "codeGuidelineFont":
      case "codeGuidelinePrimaryColor":
      case "codeGuidelineSecondaryColor":
        setCodeGuidelines({
          ...codeGuidelines,
          [property as string]: value
        });
        break;
    }
  };

  return (
    <div className="flex items-center justify-center">
      {/* Button that triggers modal */}
      <button
        className="bg-[#011d29] text-white m-10 px-4 py-2 rounded-full flex items-center"
        onClick={toggle}
      >
        <BsFillPlusCircleFill className="mr-2" /> New Code
      </button>

      {/* Modal Form */}
      {modal && (
        <div className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center">
          {/* Backdrop */}
          <div className="fixed inset-0 bg-black opacity-60 z-20" />

          {/* Modal Card */}
          <div className="bg-white p-6 rounded-lg w-full max-w-md shadow-xl z-30 border-[#011d29] border-t-4 space-y-6">
            <div>
              <h2 className="text-2xl text-[#011d29] mb-3">New Code</h2>
            </div>
            <form className="space-y-5">
              <div className="mb-2">
                <label
                  htmlFor="codeName"
                  className="font-medium text-sm block mb-2 text-[#011d29]"
                >
                  Code Name*
                </label>
                <input
                  data-property="codeName"
                  type="text"
                  value={codeName || ""}
                  className="text-[#011d29] border-[#011d29] border-[1px] focus:ring-0 focus:border-gray-400 text-sm rounded-lg py-2.5 px-4 w-full"
                  onChange={onChange}
                  required
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="codeLanguage"
                  className="font-medium text-sm block mb-2 text-[#011d29]"
                >
                  Code Language*
                </label>
                <select
                  data-property="codeLanguage"
                  className="text-[#011d29] border-[#011d29] border-[1px] focus:ring-0 focus:border-gray-400 text-sm rounded-lg py-2.5 px-4 w-full"
                  onChange={onChange}
                  required
                >
                  <option>Select code language</option>
                  <option value="html" selected={codeLanguage === "html"}>
                    Html
                  </option>
                  <option value="react" selected={codeLanguage === "react"}>
                    ReactJS
                  </option>
                </select>
              </div>

              {/* Guidelines Section */}
              <div>
                <h3 className="text-xl text-[#011d29] mb-3">
                  Guidelines{" "}
                  <span className="text-sm text-gray-600 mb-5">optional</span>
                </h3>
                <p className="text-sm text-gray-600 mb-5">
                  Providing guidelines will help codeXpert generate a more
                  cohesive design that fits your idea.
                </p>
                {/*<div className="mb-2">*/}
                {/*    <label htmlFor="codeGuidelineLogo" className="font-medium text-sm block mb-2 text-[#011d29]">Logo</label>*/}
                {/*    <label className="block border-[1px] border-[#011d29] hover:border-gray-400 bg-gray-100 cursor-pointer p-2 rounded-lg text-center text-sm text-[#011d29] focus:border-gray-400 focus:ring focus:ring-blue-200">*/}
                {/*        Upload Logo*/}
                {/*        <input*/}
                {/*            type="file"*/}
                {/*            data-property="codeGuidelineLogo"*/}
                {/*            id="codeGuidelineLogo"*/}
                {/*            className="hidden"*/}
                {/*            onChange={onChange}*/}
                {/*        />*/}
                {/*    </label>*/}
                {/*</div>*/}
                <div className="mb-2">
                  <label
                    htmlFor="codeGuidelineFont"
                    className="font-medium text-sm block mb-2 text-[#011d29]"
                  >
                    Font
                  </label>
                  <select
                    data-property="codeGuidelineFont"
                    className="text-[#011d29] border-[#011d29] border-[1px] focus:ring-0 focus:border-gray-400 text-sm rounded-lg py-2.5 px-4 w-full"
                    onChange={onChange}
                  >
                    <option value="">Select font</option>
                    <option
                      value="Roboto"
                      selected={
                        (codeGuidelines as CodeGuidelines)
                          ?.codeGuidelineFont === "Roboto"
                      }
                    >
                      Roboto
                    </option>
                    <option
                      value="Open Sans"
                      selected={
                        (codeGuidelines as CodeGuidelines)
                          ?.codeGuidelineFont === "Open Sans"
                      }
                    >
                      Open Sans
                    </option>
                    <option
                      value="Lato"
                      selected={
                        (codeGuidelines as CodeGuidelines)
                          ?.codeGuidelineFont === "Lato"
                      }
                    >
                      Lato
                    </option>
                    <option
                      value="Montserrat"
                      selected={
                        (codeGuidelines as CodeGuidelines)
                          ?.codeGuidelineFont === "Montserrat"
                      }
                    >
                      Montserrat
                    </option>
                    <option
                      value="Raleway"
                      selected={
                        (codeGuidelines as CodeGuidelines)
                          ?.codeGuidelineFont === "Raleway"
                      }
                    >
                      Raleway
                    </option>
                    <option
                      value="Oswald"
                      selected={
                        (codeGuidelines as CodeGuidelines)
                          ?.codeGuidelineFont === "Oswald"
                      }
                    >
                      Oswald
                    </option>
                    <option
                      value="Poppins"
                      selected={
                        (codeGuidelines as CodeGuidelines)
                          ?.codeGuidelineFont === "Poppins"
                      }
                    >
                      Poppins
                    </option>
                  </select>
                </div>
                <div className="mb-2">
                  <label
                    htmlFor="codeGuidelinePrimaryColor"
                    className="font-medium text-sm block mb-2 text-[#011d29]"
                  >
                    Primary Color
                  </label>
                  <input
                    type="color"
                    data-property="codeGuidelinePrimaryColor"
                    className="w-full p-2 border rounded border-[#011d29]"
                    onChange={onChange}
                    value={
                      (codeGuidelines as CodeGuidelines)
                        ?.codeGuidelinePrimaryColor
                    }
                  />
                </div>
                <div className="mb-2">
                  <label
                    htmlFor="codeGuidelineSecondaryColor"
                    className="font-medium text-sm block mb-2 text-[#011d29]"
                  >
                    Secondary Color
                  </label>
                  <input
                    type="color"
                    data-property="codeGuidelineSecondaryColor"
                    className="w-full p-2 border rounded border-[#011d29]"
                    onChange={onChange}
                    value={
                      (codeGuidelines as CodeGuidelines)
                        ?.codeGuidelineSecondaryColor
                    }
                  />
                </div>
              </div>
            </form>
            <div className="flex justify-end">
              <Button
                label="Submit"
                className="bg-[#011d29] text-white text-sm rounded-lg px-6 py-2.5"
                onClick={createCodeAction}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewCode;
