import React from "react";
import Footer from "../components/webiste/Footer";
import Header from "../components/webiste/Header";
import VerifyEmail from "../components/webiste/VerifyEmail";
import { useLocation, useParams } from "react-router-dom";

const VerifyEmailPage: React.FC = () => {
  const { token } = useParams();
  const { state } = useLocation();

  return (
    <div className="text-white bg-[transparent]">
      <video
        className="fixed top-0 left-0 w-screen h-screen object-cover z-[-10]"
        controls={false}
        playsInline
        autoPlay
        muted
        loop
      >
        <source src="/home.mp4" type="video/mp4" />
      </video>
      <Header />
      <VerifyEmail token={token} email={state?.email} />
      <Footer />
    </div>
  );
};

export default VerifyEmailPage;
