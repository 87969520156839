import React, { FC, useEffect } from "react";
import UserProfile from "../components/UserProfile";
import useStore from "../utils/store";
import Loader from "../components/Loader";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import NewCode from "../components/NewCode";
import useCode from "../hooks/useCode";

const CodesPage: FC = () => {
  const { codes, setCodes }: any = useStore();
  const { listCodes }: any = useCode();

  useEffect(() => {
    const fetchCodes = async () => {
      const operation = await listCodes();
      const { codes = [] } = operation;
      setCodes(codes);
    };

    fetchCodes();
  }, []);

  if (!codes) {
    return (
      <Loader
        isLoading={true}
        loadingMessage={"Fetching your awesome work..."}
      />
    );
  }

  return (
    <div className="codes flex flex-col h-screen">
      <section className="border-b-2 border-gray-50 bg-[#011d29]">
        <div className="flex items-right float-right justify-center">
          <UserProfile />
        </div>
      </section>
      <section className="mt-40">
        <div>
          <h1 className="py-2 md:py-4 text-xl md:text-5xl text-center font-semibold">
            Generate Limitless AI-powered
            <span className="relative bg-gray-50 px-2 py-[1px] border-dashed border-[2px] border-gray-300 rounded-[6px] ">
              Components
              <span
                className="absolute -top-5 md:-top-6 left-2 text-xs bg-gray-300 rounded-t-md p-0.5 md:p-1 font-normal select-none"
                role="presentation"
                aria-hidden="true"
              >
                span
              </span>
              <div
                className="absolute -bottom-6 md:-bottom-4 -right-5"
                role="presentation"
                aria-hidden="true"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="34"
                  height="34"
                  fill="none"
                >
                  <g filter="url(#a)">
                    <path
                      fill="#808080"
                      d="M23.373 8.253 10.944 5.905a.5.5 0 0 0-.548.697l5.25 11.616a.5.5 0 0 0 .934-.061l1.705-5.656a.5.5 0 0 1 .22-.284l5.034-3.045a.5.5 0 0 0-.166-.919Z"
                    ></path>
                    <path
                      stroke="#fff"
                      stroke-width="1.5"
                      d="m11.083 5.168 12.43 2.348c1.126.213 1.395 1.704.414 2.298l-4.951 2.995-1.678 5.564c-.334 1.106-1.86 1.206-2.336.154L9.712 6.91a1.25 1.25 0 0 1 1.371-1.743Zm7.92 7.55-.24-.073.24.072Z"
                    ></path>
                  </g>
                  <defs>
                    <filter
                      id="a"
                      width="32.434"
                      height="31.618"
                      x="0.848"
                      y="0.395"
                      color-interpolation-filters="sRGB"
                      filterUnits="userSpaceOnUse"
                    >
                      <feFlood
                        flood-opacity="0"
                        result="BackgroundImageFix"
                      ></feFlood>
                      <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      ></feColorMatrix>
                      <feOffset dy="4"></feOffset>
                      <feGaussianBlur stdDeviation="4"></feGaussianBlur>
                      <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"></feColorMatrix>
                      <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_868_68528"
                      ></feBlend>
                      <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      ></feColorMatrix>
                      <feOffset dy="1"></feOffset>
                      <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
                      <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0"></feColorMatrix>
                      <feBlend
                        in2="effect1_dropShadow_868_68528"
                        result="effect2_dropShadow_868_68528"
                      ></feBlend>
                      <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      ></feColorMatrix>
                      <feOffset dy="2"></feOffset>
                      <feGaussianBlur stdDeviation="1"></feGaussianBlur>
                      <feComposite in2="hardAlpha" operator="out"></feComposite>
                      <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"></feColorMatrix>
                      <feBlend
                        in2="effect2_dropShadow_868_68528"
                        result="effect3_dropShadow_868_68528"
                      ></feBlend>
                      <feBlend
                        in="SourceGraphic"
                        in2="effect3_dropShadow_868_68528"
                        result="shape"
                      ></feBlend>
                    </filter>
                  </defs>
                </svg>
              </div>
            </span>
          </h1>
        </div>
        <div className="flex items-center justify-center">
          <div className="max-w-[350px] md:max-w-[573px] text-center pt-6 pb-14">
            Create elegant and sophisticated components in just a few prompts.
            Export the code with 1-click and use in your own projects.
          </div>
        </div>
        <NewCode />
      </section>

      {codes && codes.length > 0 && (
        <section className="py-10 px-4">
          <div className="m-20 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {codes.map((code: any, id: any) => (
              <div
                key={id}
                className="flex items-start bg-white p-4 rounded shadow-lg border border-gray-200 hover:shadow-xl transition-shadow duration-300"
              >
                <img
                  src={
                    code.codeLanguage === "html"
                      ? "images/html.png"
                      : "images/reactjs.png"
                  }
                  alt={code.codeName || code.codeSessionId}
                  className="w-[100px] rounded-t mr-4"
                />
                <div className="flex-grow">
                  <h3 className="text-xl text-[#011d29] mb-2">
                    {code.codeName || code.codeSessionId}
                  </h3>
                  <Link
                    to={`/code/${code.codeSessionId}`}
                    className="ms-2 text-[#011d29]"
                  >
                    <FaEdit />
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </section>
      )}
    </div>
  );
};

export default CodesPage;
