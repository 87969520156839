export interface GeneratedCode {
  newCode?: string;
  oldCode?: string;
  prompt?: string;
  codeLanguage: CodeLanguages;
}

export enum CodeLanguages {
  html = "html",
  react = "react"
}
