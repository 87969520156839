import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useCode from "../hooks/useCode";
import { CodeLanguages } from "../types";
import CodeRender from "../components/CodeRender";
import Loader from "../components/Loader";

const SessionCodePreviewPage: React.FC = () => {
  const { sessionId } = useParams();
  const { renderCode } = useCode();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [code, setCode] = useState<string | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [codeLanguage, setCodeLanguage] = useState<CodeLanguages | null>(null);

  useEffect(() => {
    if (sessionId !== null) {
      fetchRenderCode();
    }
  }, [sessionId]);

  const fetchRenderCode = async () => {
    const operation = await renderCode(sessionId as string);
    const { message, code } = operation;
    switch (message) {
      case "Code rendered successfully": {
        await retrieveCode(code);
        break;
      }
      default:
        setError(true);
        setIsLoading(false);
        break;
    }
  };

  const retrieveCode = async (code: {
    codeLanguage: CodeLanguages;
    codePath: string;
  }) => {
    const { codeLanguage, codePath } = code;
    fetch(codePath)
      .then((response) => {
        if (!response.ok) {
          setError(true);
          setIsLoading(false);
          return null;
        }
        return response.text();
      })
      .then((data) => {
        setCodeLanguage(codeLanguage);
        setCode(data);
        setIsLoading(false);
      })
      .catch(() => {
        setError(true);
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading || error ? (
        <Loader
          isLoading={isLoading}
          loadingMessage={"Initializing code base..."}
        />
      ) : (
        <>
          <CodeRender
            code={code || ""}
            codeReference={sessionId as string}
            codeIndex={0}
            codeLanguage={codeLanguage || CodeLanguages.html}
            withWrapper={false}
          />
        </>
      )}
    </>
  );
};

export default SessionCodePreviewPage;
