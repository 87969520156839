import { HiOutlineExclamation } from "react-icons/hi";

interface ErrorMessageProps {
  error: string;
}

const ErrorMessage = ({ error }: ErrorMessageProps) => (
  <div className="bg-red-50 rounded-md p-2 text-red-900 flex items-center">
    <HiOutlineExclamation size={20} />
    <span className="ml-2">{error}</span>
  </div>
);

export default ErrorMessage;
