import { FaCopy, FaDownload, FaHistory, FaShare } from "react-icons/fa";
import React, { ChangeEvent } from "react";
import { CodeLanguages, GeneratedCode } from "../types";
import { Button } from "./Button";

interface CodeActionsProps {
  generatedCodeHistory: GeneratedCode[];
  description: string;
  codeLanguage: CodeLanguages;
  handleClear: () => void;
  handleSubmit: () => void;
  handlePublishCode: () => void;
  handleSwitchHistory: (e: ChangeEvent<HTMLSelectElement>) => void;
  setCodeLanguage: (value: CodeLanguages) => void;
  code: string;
  isLoading: boolean;
  codeReference: string;
}

const CodeActions: React.FC<CodeActionsProps> = ({
  handleSwitchHistory,
  generatedCodeHistory,
  code,
  isLoading,
  description,
  handleClear,
  handleSubmit,
  handlePublishCode,
  codeReference
}) => {
  const downloadCode = () => {
    const blob = new Blob([code], { type: "text/html" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${codeReference}.html`;
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <div className="flex items-center justify-center bg-[#011d29] py-4 h-full">
      {generatedCodeHistory.length > 0 && (
        <div className="flex items-center justify-center border-l-2 border-gray-50 text-gray-400">
          <Button
            className="px-3"
            onClick={() => navigator.clipboard.writeText(code)}
            label={<FaCopy />}
          />
          <Button
            className="px-3"
            onClick={downloadCode}
            label={<FaDownload />}
          />
          <Button
            className="px-3"
            onClick={handlePublishCode}
            label={<FaShare />}
          />
          {generatedCodeHistory.length > 1 && (
            <div className="flex items-center justify-center mx-3">
              <FaHistory />
              <select
                onChange={handleSwitchHistory}
                className="mx-3 px-2 border-2 cursor-pointer focus:outline-none focus-visible:outline-none rounded-lg min-w-60 w-60 text-[12px]"
              >
                <option value="">Revisions</option>
                {generatedCodeHistory.map((generatedCode, index) => (
                  <option value={index} key={index}>
                    {generatedCode.prompt}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
      )}

      <Button
        className="btn-clear border-l-2 border-gray-50"
        onClick={handleClear}
        disabled={isLoading || (!description && !code)}
        label="Clear"
      />
      <Button
        className="btn-generate"
        onClick={() => handleSubmit()}
        disabled={!description}
        isLoading={isLoading}
        label="Generate"
      />
    </div>
  );
};

export default CodeActions;
