import React from "react";
import Footer from "../components/webiste/Footer";
import Header from "../components/webiste/Header";
import ResetPassword from "../components/webiste/ResetPassword";
import { useParams } from "react-router-dom";

const ResetPasswordPage: React.FC = () => {
  const { token } = useParams();

  return (
    <div className="text-white bg-[transparent]">
      <video
        className="fixed top-0 left-0 w-screen h-screen object-cover z-[-10]"
        controls={false}
        playsInline
        autoPlay
        muted
        loop
      >
        <source src="/home.mp4" type="video/mp4" />
      </video>
      <Header />
      <ResetPassword token={token} />
      <Footer />
    </div>
  );
};

export default ResetPasswordPage;
