import React from "react";
import Editor, { DiffEditor } from "@monaco-editor/react";
import { debounce } from "lodash";
import { Button } from "./Button";
import { CodeLanguages, GeneratedCode } from "../types";

const sharedEditorProps = {
  theme: "vs-dark",
  options: {
    wordWrap: "on"
  }
};

const editorLanguage: Record<CodeLanguages, string> = {
  [CodeLanguages.html]: "html",
  [CodeLanguages.react]: "javascript"
};

interface CodePreviewProps {
  code: string;
  isLoading: boolean;
  showDiffEditor: boolean;
  generatedCode: GeneratedCode;
  onChange: (code: string) => void;
  setShowDiffEditor: (show: boolean) => void;
}

const CodePreview: React.FC<CodePreviewProps> = ({
  code,
  isLoading,
  showDiffEditor,
  generatedCode: { oldCode, codeLanguage },
  setShowDiffEditor,
  onChange
}) => {
  const debounceOnChange = debounce(onChange, 500);

  // add onChange event to diffEditor
  const handleDiffEditorOnMount = (editor: any) => {
    const modifiedEditor = editor.getModifiedEditor();
    modifiedEditor.onDidChangeModelContent(() => {
      debounceOnChange(modifiedEditor.getValue() || "");
    });
  };

  return (
    <section className="code-section h-full w-full overflow-y-hidden flex flex-col">
      <div className="p-[10px] shadow-md text-gray-400 leading-[25px] bg-[#011d29] flex items-center justify-between">
        <h2>Code</h2>
        <div className="flex items-cente">
          <Button
            onClick={() => setShowDiffEditor(false)}
            isActive={!showDiffEditor}
            label="Editor"
          />
          <Button
            onClick={() => setShowDiffEditor(true)}
            isActive={showDiffEditor}
            label="Diff editor"
            disabled={!oldCode?.length}
          />
        </div>
      </div>
      <div className="flex-1 pt-1">
        {showDiffEditor ? (
          <DiffEditor
            {...sharedEditorProps}
            language={editorLanguage[codeLanguage]}
            original={oldCode}
            modified={code}
            onMount={handleDiffEditorOnMount}
          />
        ) : (
          <Editor
            {...sharedEditorProps}
            language={editorLanguage[codeLanguage]}
            value={code}
            onChange={(value) =>
              !isLoading ? debounceOnChange(value || "") : ""
            }
          />
        )}
      </div>
    </section>
  );
};

export default CodePreview;
