import React, { useEffect, useState } from "react";
import CodePreview from "../components/CodePreview";
import CodeRender from "../components/CodeRender";
import CodeChat from "../components/CodeChat";
import CodeVoiceChat from "../components/CodeVoiceChat";
import CodeActions from "../components/CodeActions";
import { defaultTemplates } from "../store/templates";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { CodeLanguages, GeneratedCode } from "../types";
import SplashScreenPage from "../pages/SplashScreenPage";
import { Button } from "../components/Button";
import useCode from "../hooks/useCode";
import { toastError, toastSuccess } from "../utils/toast";
import UserProfile from "../components/UserProfile";
import useStore from "../utils/store";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../components/Loader";

const INITIAL_CODE_INDEX = -1;

const CodePage: React.FC = () => {
  const { codes } = useStore();
  const { codeSessionId } = useParams();
  const navigate = useNavigate();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [description, setDescription] = useState("");
  const [generatedCodeHistory, setGeneratedCodeHistory] = useState<
    GeneratedCode[]
  >([]);
  const [codeIndex, setCodeIndex] = useState(INITIAL_CODE_INDEX);
  const [code, setCode] = useState("");
  const [codeGuidelines, setCodeGuidelines] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isCodeSessionLoading, setIsCodeSessionLoading] = useState(true);
  const [codeReference] = useState(codeSessionId as string);
  const [showDiffEditor, setShowDiffEditor] = useState(false);
  const [codeLanguage, setCodeLanguage] = useState<CodeLanguages>(
    CodeLanguages.html
  );
  const { generateCode, publishCode } = useCode();

  useEffect(() => {
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", updateScreenWidth);
    return () => {
      window.addEventListener("resize", updateScreenWidth);
    };
  }, [screenWidth]);

  if (screenWidth < 1000) {
    return <SplashScreenPage />;
  }

  useEffect(() => {
    setIsLoading(true);
    const sessionCode = codes.find(
      (code: any) => code.codeSessionId === codeSessionId
    );
    if (!codeSessionId || !sessionCode) {
      toastError("Code not found!");
      return navigate("/codes");
    }
    retrieveSessionCode(sessionCode.codePath);
    if (sessionCode.codeGuidelines) {
      setCodeGuidelines(sessionCode.codeGuidelines);
    }
    if (sessionCode.codeLanguage) {
      setCodeLanguage(sessionCode.codeLanguage);
    }
  }, [codeSessionId, codes, navigate]);

  const retrieveSessionCode = async (sessionCodePath: string) => {
    fetch(sessionCodePath)
      .then((response) => {
        if (!response.ok) {
          toastError("Can not fetch code!");
          navigate("/codes");
          setIsLoading(false);
          setIsCodeSessionLoading(false);
          return null;
        }
        return response.text();
      })
      .then((data) => {
        setCode(data as string);
        setIsLoading(false);
        setIsCodeSessionLoading(false);
      })
      .catch(() => {
        toastError("Can not fetch code!");
        navigate("/codes");
        setIsLoading(false);
        setIsCodeSessionLoading(false);
        return null;
      });
  };

  const handleClear = () => {
    setDescription("");
    setCode("");
    setGeneratedCodeHistory([]);
    setCodeIndex(INITIAL_CODE_INDEX);
    setShowDiffEditor(false);
  };

  const handleSetCodeLanguage = (codeLanguage: CodeLanguages) => {
    setCodeLanguage(codeLanguage);
    setCode("");
    setGeneratedCodeHistory([]);
    setCodeIndex(INITIAL_CODE_INDEX);
    setShowDiffEditor(false);
  };

  const handleSwitchHistory = (event: any) => {
    const generatedCodeHistoryIndex = event.target.value;
    if (generatedCodeHistory[generatedCodeHistoryIndex]) {
      const data = generatedCodeHistory[generatedCodeHistoryIndex];
      setCode(data.newCode || "");
      setCodeIndex(generatedCodeHistoryIndex);
      if (showDiffEditor && !data.oldCode?.length) {
        setShowDiffEditor(false);
      }
    }
  };

  const submitTranscript = (transcript: string) => {
    setDescription(transcript);
    handleSubmit(transcript);
  };

  const handleSubmit = async (transcript?: string) => {
    setIsLoading(true);
    setShowDiffEditor(false);
    const operation = await generateCode(
      transcript || description,
      codeLanguage,
      code,
      codeGuidelines
    );
    if (operation.status !== 200) {
      toastError(
        "Something went wrong when generating code, please try again!"
      );
      setIsLoading(false);
      return;
    }
    const oldCode = code;
    const reader = operation.body.getReader();

    const processStream = async () => {
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          setDescription("");
          setCodeIndex(codeIndex + 1);
          setGeneratedCodeHistory([
            ...generatedCodeHistory,
            {
              prompt: transcript || description,
              oldCode: oldCode,
              newCode: code,
              codeLanguage
            }
          ]);
          if (code?.length && !showDiffEditor) {
            setShowDiffEditor(true);
          }
          setIsLoading(false);
          break;
        }
        const chunk = new TextDecoder("utf-8").decode(value);
        const sanitizedChunk = chunk.replace(/^data: /, "");
        try {
          const { response: generatedCode } = JSON.parse(sanitizedChunk);
          switch (true) {
            case !!generatedCode:
              setCode(generatedCode);
              break;
            default:
              break;
          }
        } catch (e: any) {
          // do nothing
        }
      }
    };

    processStream().catch((err) => {
      console.log("--stream error--", err);
      toastError("Something went wrong when generating code!");
      setIsLoading(false);
    });
  };

  const handlePublishCode = async () => {
    setIsLoading(true);
    toastSuccess("Publishing code!");
    const operation = await publishCode(codeReference, codeLanguage, code);
    const { message, code: publishedCode } = operation;
    switch (message) {
      case "Code published successfully":
        if (publishedCode?.codePath) {
          await navigator.clipboard.writeText(publishedCode?.codePath);
          toastSuccess(
            "Code published successfully. Shareable link copied to clipboard!"
          );
        }
        break;
      default:
        break;
    }
    setIsLoading(false);
  };

  const renderedCode = code !== "" ? code : defaultTemplates[codeLanguage];

  const handleFeedbackClick = () => {
    window.open("https://forms.gle/LzBCXVvWbTtNftdQ6", "_blank");
  };

  if (isCodeSessionLoading) {
    return <Loader isLoading={true} loadingMessage={"Fetching code..."} />;
  }

  return (
    <div className="component-builder bg-[#011d29] flex flex-col h-screen">
      <div className="fixed top-1/2 -right-[100px] transform -translate-x-1/2 -rotate-90 z-[9999]">
        <Button
          label="Feedback?"
          className="bg-[#011d29] p-2"
          onClick={handleFeedbackClick}
        />
      </div>

      <section className="border-b-2 border-gray-50">
        <div className="flex items-center justify-center">
          <CodeVoiceChat
            isLoading={isLoading}
            setLoading={setIsLoading}
            submitTranscript={submitTranscript}
          />

          <CodeChat
            description={description}
            isLoading={isLoading}
            setDescription={setDescription}
            codeIndex={codeIndex}
            handleSubmit={handleSubmit}
          />

          <CodeActions
            handleSwitchHistory={handleSwitchHistory}
            generatedCodeHistory={generatedCodeHistory}
            code={renderedCode}
            isLoading={isLoading}
            description={description}
            handleClear={handleClear}
            handleSubmit={handleSubmit}
            handlePublishCode={handlePublishCode}
            codeLanguage={codeLanguage}
            setCodeLanguage={handleSetCodeLanguage}
            codeReference={codeReference}
          />

          <UserProfile />
        </div>
      </section>
      <section className="overflow-hidden flex-1">
        <PanelGroup direction="horizontal">
          <Panel defaultSize={66}>
            <CodePreview
              isLoading={isLoading}
              code={renderedCode}
              showDiffEditor={showDiffEditor}
              generatedCode={
                generatedCodeHistory[codeIndex] || { codeLanguage }
              }
              onChange={setCode}
              setShowDiffEditor={setShowDiffEditor}
            />
          </Panel>
          <PanelResizeHandle className="w-1 bg-gray-50" />
          <Panel>
            <CodeRender
              isLoading={isLoading}
              code={renderedCode}
              codeReference={codeReference}
              codeIndex={codeIndex}
              codeLanguage={codeLanguage}
            />
          </Panel>
        </PanelGroup>
      </section>
    </div>
  );
};

export default CodePage;
