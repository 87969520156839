import React, { useEffect } from "react";
import { examples, features, techStacks } from "../../store/examples";
import { Link } from "react-router-dom";

const Home = () => {
  const [showTypingElement, setShowTypingElement] = React.useState(true);
  const [showButtonElement, setShowButtonElement] = React.useState(false);
  useEffect(() => {
    setTimeout(function () {
      setShowTypingElement(false);
      setShowButtonElement(true);
    }, 5000);
  }, []);

  return (
    <section className="text-black body-font lg:pt-20">
      <div className="container px-5 pt-32 mx-auto lg:px-4 lg:py-4">
        <div className="flex flex-col w-full mb-2 text-left md:text-center">
          <h1 className="mt-40 mb-2 text-6xl font-bold tracking-tighter text-white lg:text-8xl md:text-7xl">
            <span>Unleash the Creative Power of Words & Voice into Code!</span>
            <br className="hidden lg:block" />
          </h1>
          <br />
          <div className="text-center">
            <div
              className="mt-10 border-2 border-gray-500 rounded-full px-6 py-4"
              style={{ display: showTypingElement ? "inline-block" : "none" }}
            >
              <div className="typewriter">
                <h1 className="text-white smMax:text-[12px]">
                  Create a "Try Now!" button that takes me to{" "}
                  <strong>codeXpert</strong>
                </h1>
              </div>
            </div>
            <button
              style={{ display: showButtonElement ? "inline-block" : "none" }}
              className="mt-10 px-6 py-3 border-2 border-white rounded-full text-lg bg-white text-black hover:bg-transparent hover:text-white transition duration-300"
            >
              <Link to="/sign-in">Try Now!</Link>
            </button>
          </div>
        </div>
      </div>
      <div className="container flex flex-col items-center justify-center py-8 mx-auto rounded-lg md:p-1 p-3 drop-shadow-2xl">
        <img
          className="object-cover object-center w-full m-10 border-gray-200 dark:border-gray-900 g327 border rounded-lg shadow-md"
          alt="demo"
          src="/demo.png"
        />
      </div>
      <div className="bg-black mx-auto pt-32 px-4">
        <div className="text-center">
          <h2 className="mb-2 text-6xl font-bold tracking-tighter text-white lg:text-8xl md:text-7xl">
            Features
          </h2>
          <br />
          <p className="mx-auto  text-xl font-normal leading-relaxed text-gray-600 dark:text-gray-300 lg:w-2/3">
            What can codeXpert help you with?
          </p>
          <div className="flex mt-6 justify-center">
            <div className="w-16 h-1 rounded-full bg-white inline-flex" />
          </div>
        </div>
        <div className="container lg:p-20 mx-auto">
          <div className="flex flex-wrap -m-4">
            {features.map((feature) => (
              <div className="p-10 lg:w-1/3 md:w-1">
                <div>
                  <img
                    alt="example"
                    className="flex-shrink-0 m-auto rounded-lg w-20 object-cover object-center mb-4"
                    src={feature.featureIcon}
                  />
                </div>
                <div className="h-full flex flex-col items-center text-center">
                  <div className="w-full">
                    <h2 className="title-font font-medium text-lg text-white">
                      {feature.featureName}
                    </h2>
                    <h3 className="text-gray-500 m-3">
                      {feature.featureDescription}
                    </h3>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="container mx-auto pt-32 px-4">
        <div className="text-center">
          <h2 className="mb-2 text-6xl font-bold tracking-tighter text-white lg:text-8xl md:text-7xl">
            Supported Stacks
          </h2>
          <br />
          <p className="mx-auto  text-xl font-normal leading-relaxed text-gray-600 dark:text-gray-300 lg:w-2/3">
            codeXpert supports the following tech stacks
          </p>
          <div className="flex mt-6 justify-center">
            <div className="w-16 h-1 rounded-full bg-white inline-flex" />
          </div>
        </div>
        <div className="container lg:p-20 mx-auto">
          <div className="flex flex-wrap">
            {techStacks.map((techStack) => (
              <div className="lg:p-10 md:p-10 sm:p-10 lg:w-1/5 md:w-1/2 sm:w-1/2 p-5 w-1/3">
                <div className="h-full flex flex-col items-center text-center">
                  <img
                    alt="example"
                    className="flex-shrink-0 rounded-lg w-full lg:h-100 md:h-50 sm:h-20 object-contain object-center mb-4"
                    src={techStack}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="bg-black mx-auto pt-32 px-4">
        <div className="text-center">
          <h2 className="mb-2 text-6xl font-bold tracking-tighter text-white lg:text-8xl md:text-7xl">
            Explore
          </h2>
          <br />
          <p className="mx-auto  text-xl font-normal leading-relaxed text-gray-600 dark:text-gray-300 lg:w-2/3">
            Some examples of what you can build with CodeXpert
          </p>
          <div className="flex mt-6 justify-center">
            <div className="w-16 h-1 rounded-full bg-white inline-flex" />
          </div>
        </div>
        <div className="container lg:p-20 mx-auto">
          <div className="flex flex-wrap -m-4">
            {examples.map((example) => (
              <div className="p-10 lg:w-1/3 md:w-1">
                <div className="h-full flex flex-col items-center text-center">
                  <img
                    alt="example"
                    className="flex-shrink-0 rounded-lg w-full h-100 object-cover object-center mb-4"
                    src={example.examplePreview}
                  />
                  <div className="w-full">
                    <h2 className="title-font font-medium text-lg text-white">
                      {example.exampleName}
                    </h2>
                    <h3 className="text-gray-500 m-3">
                      {example.examplePrompt}
                    </h3>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="text-center mt-4">
            <div className="flex mb-6 justify-center">
              <div className="w-16 h-1 rounded-full bg-white inline-flex" />
            </div>
            <p className="mx-auto p-5 text-2xl font-normal leading-relaxed text-white dark:text-gray-300 lg:w-2/3">
              <strong>Or anything else you can think of...</strong>
            </p>
          </div>
        </div>
      </div>
      <div className="container mx-auto p-32 px-4">
        <div className="text-center">
          <h2 className="mb-2 text-6xl font-bold tracking-tighter text-white lg:text-8xl md:text-7xl">
            And many more...
          </h2>
        </div>
      </div>
    </section>
  );
};

export default Home;
