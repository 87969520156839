import React, { useState } from "react";
import { useRunner } from "react-runner";
import ErrorMessage from "./ErrorMessage";
import * as FaReactIcons from "react-icons/fa";
import * as FiReactIcons from "react-icons/fi";
import * as BiReactIcons from "react-icons/bi";
import * as RiReactIcons from "react-icons/ri";
import * as AiReactIcons from "react-icons/ai";
import * as BsReactIcons from "react-icons/bs";
import * as IoReactIcons from "react-icons/io";
import * as ReactDom from "react-dom";
import * as ReactStrap from "reactstrap";

interface ReactCodeRendererProps {
  code: string;
  codeIndex: number;
}

const scope = {
  ...React,
  import: {
    react: React,
    "react-dom": ReactDom,
    reactstrap: ReactStrap,
    "react-icons/fa": FaReactIcons,
    "react-icons/fi": FiReactIcons,
    "react-icons/bi": BiReactIcons,
    "react-icons/ri": RiReactIcons,
    "react-icons/ai": AiReactIcons,
    "react-icons/bs": BsReactIcons,
    "react-icons/io": IoReactIcons
  }
};

const ReactCodeRenderer = ({ code, codeIndex }: ReactCodeRendererProps) => {
  const [iframeRef, setIframeRef] = useState<HTMLIFrameElement | null>(null);
  const mountBodyNode = iframeRef?.contentWindow?.document?.body;
  const mountHeadNode = iframeRef?.contentWindow?.document?.head;

  const { element, error } = useRunner({ code, scope });
  const { element: styleElement, error: styleError } = useRunner({
    code: '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css"/>',
    scope
  });

  if (error || !element || !styleElement || styleError) {
    return (
      <div className="p-2">
        <ErrorMessage error={error || "Unable to render component preview"} />
      </div>
    );
  }

  return (
    <iframe
      key={`react-${codeIndex}`}
      ref={setIframeRef}
      title="React Component Render"
      className="w-full h-full"
      sandbox="allow-same-origin allow-scripts allow-popups allow-modals allow-forms"
    >
      {mountHeadNode && ReactDom.createPortal(styleElement, mountHeadNode)}
      {mountBodyNode && ReactDom.createPortal(element, mountBodyNode)}
    </iframe>
  );
};

export default ReactCodeRenderer;
