import { FC } from "react";

interface CodeChatProps {
  description: string;
  isLoading: boolean;
  codeIndex: number;
  setDescription: (description: string) => void;
  handleSubmit: () => void;
}

const CodeChat: FC<CodeChatProps> = ({
  description,
  isLoading,
  codeIndex,
  setDescription,
  handleSubmit
}) => {
  const handleEnterAction = () => {
    if (!isLoading && description) {
      handleSubmit();
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      handleEnterAction();
    }
  };

  return (
    <input
      className="textarea p-4 w-full bg-[#011d29] focus:outline-none focus-visible:outline-none text-white"
      value={description}
      onChange={(e) => setDescription(e.target.value)}
      placeholder={
        codeIndex === -1
          ? "Describe your app and i'll try to build it for you..."
          : "Want to change anything with the result, just mention it and i'll make it happen..."
      }
      onKeyPress={handleKeyPress}
      disabled={isLoading}
    />
  );
};

export default CodeChat;
